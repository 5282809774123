import { useEffect, useState, type FC } from "react";

export const Clock: FC = () => {
	const [time, setTime] = useState(
		new Date().toLocaleTimeString()
	);

	useEffect(() => {
		const interval = setInterval(() => {
			const date = new Date();
			let minutes: number | string = date.getMinutes();
			let hours: number | string = date.getHours();

			if (minutes < 10) {
				minutes = `0${minutes}`;
			}

			if (hours < 10) {
				hours = `0${hours}`;
			}
			setTime(`${hours}:${minutes}`);
		}, 1000);
		return () => {
			clearInterval(interval);
		};
	}, []);

	return (
		<div>
			<h1>{time}</h1>
		</div>
	);
};
