import {
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";

import { MediaConfig } from "../configs/mediaConfig";

export const useMedia = () => {
	const userMediaRef = useRef<MediaStream | null>(null);
	const userScreenRef = useRef<MediaStream | null>(null);

	const userVideoElementRef =
		useRef<HTMLVideoElement | null>(null);

	const [isAudioPlaying, setIsAudioPlaying] =
		useState(false);
	const [isVideoPlaying, setIsVideoPlaying] =
		useState(false);
	const [isScreenShared, setIsScreenShared] =
		useState(false);

	const removeTrackListener = useCallback(() => {
		if (!userVideoElementRef.current) {
			return;
		}
		userVideoElementRef.current.srcObject = null;
	}, []);

	const onTrackEndListener = useCallback(() => {
		console.log("Track ended");
	}, []);

	const addRemoveTrackListener = useCallback(() => {
		userMediaRef.current?.addEventListener(
			"removetrack",
			removeTrackListener
		);
		userMediaRef.current?.getTracks().forEach((track) => {
			track.addEventListener("ended", removeTrackListener);
		});
	}, []);

	const deviceChangeListener = useCallback(async () => {
		if (!userMediaRef.current) {
			return;
		}
		userMediaRef.current.getTracks().forEach((track) => {
			track.stop();
			track.enabled = false;
			userMediaRef.current!.removeTrack(track);
		});

		const newUserMedia =
			await navigator.mediaDevices.getUserMedia(
				MediaConfig
			);

		const audioTracks = newUserMedia.getAudioTracks();
		const videoTracks = newUserMedia.getVideoTracks();
		userVideoElementRef.current!.srcObject = newUserMedia;

		userMediaRef.current.addTrack(audioTracks[0]);
		userMediaRef.current.addTrack(videoTracks[0]);
	}, []);

	const addDeviceChangeListener = useCallback(() => {
		navigator.mediaDevices.addEventListener(
			"devicechange",
			deviceChangeListener
		);
	}, []);

	const cleanUp = useCallback(() => {
		if (userMediaRef.current) {
			userMediaRef.current.getTracks().forEach((track) => {
				track.stop();
				track.enabled = false;
				userMediaRef.current!.removeTrack(track);
			});
			setIsAudioPlaying(false);
			setIsVideoPlaying(false);
			userMediaRef.current.removeEventListener(
				"removetrack",
				removeTrackListener
			);
		}
		if (userVideoElementRef.current) {
			userVideoElementRef.current.srcObject = null;
		}
		userScreenRef.current?.getTracks().forEach((track) => {
			track.removeEventListener(
				"ended",
				onTrackEndListener
			);
			track.stop();
			track.enabled = false;
			userScreenRef.current!.removeTrack(track);
		});
		setIsScreenShared(false);
		navigator.mediaDevices.removeEventListener(
			"devicechange",
			deviceChangeListener
		);
	}, []);

	useEffect(() => {
		return () => {
			cleanUp();
		};
	}, []);

	const askPermissionsForMedia = async () => {
		try {
			const userMediaStream =
				await navigator.mediaDevices.getUserMedia(
					MediaConfig
				);
			userMediaRef.current = userMediaStream;
			userVideoElementRef.current!.srcObject =
				userMediaStream;
		} catch (error) {
			console.error("Error while getting media", error);
		}
	};

	const askPermissionForScreenShare = async () => {
		const displayMediaWithVideo =
			await navigator.mediaDevices.getDisplayMedia({
				video: true,
			});
		const displayMediaWithAudio =
			await navigator.mediaDevices.getUserMedia({
				audio: true,
			});
		const userScreenStream = new MediaStream([
			...displayMediaWithVideo.getVideoTracks(),
			...displayMediaWithAudio.getAudioTracks(),
		]);

		userScreenRef.current = userScreenStream;
		if (
			userScreenRef.current
				.getVideoTracks()[0]
				// @ts-expect-error - Property 'displaySurface' does not exist on type 'MediaTrackSettings'
				.getSettings().displaySurface !== "monitor"
		) {
			throw new Error("Screen sharing not allowed");
		}
	};

	const toggleAudio = () => {
		userMediaRef.current
			?.getAudioTracks()
			.forEach((track) => {
				track.enabled = !track.enabled;
			});
		setIsAudioPlaying((prev) => !prev);
	};

	const toggleVideo = () => {
		userMediaRef.current
			?.getVideoTracks()
			.forEach((track) => {
				track.enabled = !track.enabled;
			});
		setIsVideoPlaying((prev) => !prev);
	};

	const toggleScreen = () => {
		userScreenRef.current?.getTracks().forEach((track) => {
			track.stop();
			track.enabled = !track.enabled;
			userScreenRef.current?.removeTrack(track);
		});
		setIsScreenShared((prev) => !prev);
	};

	return {
		isAudioPlaying,
		toggleAudio,
		isVideoPlaying,
		toggleVideo,
		isScreenShared,
		toggleScreen,
		userMediaRef,
		userScreenRef,
		userVideoElementRef,
		addRemoveTrackListener,
		addDeviceChangeListener,
		askPermissionsForMedia,
		askPermissionForScreenShare,
		cleanUp,
	};
};
