import { useState, type FC } from "react";
import { FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import { MdCallEnd } from "react-icons/md";

import { useCall } from "../hooks/useCall";

import { CallEndModal } from "./CallEndModal";
import { Switch } from "./Switch";

export const MediaControls: FC = () => {
	const call = useCall();
	const {
		isAudioPlaying,
		// isVideoMuted,
		// isScreenShared,
		toggleAudio,
		// toggleVideo,
		toggleConversation,
		// toggleScreen,
	} = call;

	const [open, setOpen] = useState(false);

	const handleConfirm = () => {
		toggleConversation();
		setOpen(false);
	};

	return (
		<div className="controls">
			<Switch
				name="audio"
				checked={isAudioPlaying}
				onChange={toggleAudio}
				checkedColor="lightblue"
				uncheckedColor="#e0e0e0"
				checkedIcon={<FaVolumeMute />}
				uncheckedIcon={<FaVolumeUp />}
			/>
			{/* <Switch
				name="video"
				checked={isVideoMuted}
				onChange={toggleVideo}
				checkedColor="lightblue"
				uncheckedColor="#e0e0e0"
				checkedIcon={<FiCameraOff />}
				uncheckedIcon={<FiCamera />}
			/> */}
			<button
				onClick={() => {
					setOpen(true);
				}}
			>
				<MdCallEnd
					color="white"
					size={24}
				/>
			</button>
			<CallEndModal
				open={open}
				onConfirm={handleConfirm}
				onDecline={() => {
					setOpen(false);
				}}
			/>
			{/* <Switch
				name="screen"
				checked={isScreenShared}
				onChange={toggleScreen}
				checkedColor="lightblue"
				uncheckedColor="#e0e0e0"
				checkedIcon={<TbScreenShareOff />}
				uncheckedIcon={<TbScreenShare />}
			/> */}
		</div>
	);
};
