import {
	createContext,
	useCallback,
	useEffect,
	useRef,
	useState,
	type Dispatch,
	type FC,
	type MutableRefObject,
	type ReactNode,
	type SetStateAction,
} from "react";
import { RetellWebClient } from "retell-client-js-sdk";

import { type TAudioVisualizerRef } from "../components/AudioVisualizer";
import { Config } from "../configs";
import { useMedia } from "../hooks/useMedia";
import { Services } from "../services";
import {
	type TInterviewDetails,
	type TRegisterCallResponse,
} from "../types/call";
import { setAuthCookie } from "../utils/authCookie";
import { notifyError } from "../utils/notification";
import { RecordingBlobQueue } from "../utils/recordingBlobQueue";

export type TTranscript = {
	role: string;
	content: string;
};

export type TCallContext = {
	callDetails?: TRegisterCallResponse | null;
	transcript?: TTranscript | null;
	isCalling: boolean;
	isAudioPlaying: boolean;
	isVideoPlaying: boolean;
	isScreenShared: boolean;
	showCallInfo: boolean;
	isLoading: boolean;
	questionStatement: string;
	interviewDetails?: TInterviewDetails | null;
	userVideoElementRef?: MutableRefObject<HTMLVideoElement | null> | null;
	audioVisualizerRef?: MutableRefObject<TAudioVisualizerRef | null> | null;
	toggleConversation: () => void;
	toggleAudio: () => void;
	toggleVideo: () => void;
	toggleScreen: () => void;
	setShowCallInfo: Dispatch<SetStateAction<boolean>>;
	askPermissionForScreenShare: () => Promise<void>;
	userScreenRef?: MutableRefObject<MediaStream | null> | null;
};

export const CallContext = createContext<TCallContext>({
	isAudioPlaying: false,
	isVideoPlaying: false,
	isScreenShared: false,
	isCalling: false,
	showCallInfo: false,
	isLoading: false,
	questionStatement: "",
	toggleAudio: () => {},
	toggleVideo: () => {},
	toggleScreen: () => {},
	toggleConversation: () => {},
	setShowCallInfo: () => {},
	askPermissionForScreenShare: async () => {},
});

type TCallProviderProps = {
	children: ReactNode;
};

export const CallProvider: FC<TCallProviderProps> = ({
	children,
}) => {
	const {
		userMediaRef,
		addDeviceChangeListener,
		addRemoveTrackListener,
		askPermissionsForMedia,
		askPermissionForScreenShare,
		userScreenRef,
		userVideoElementRef,
		cleanUp: mediaCleanUp,
		isAudioPlaying,
		isScreenShared,
		isVideoPlaying,
		toggleAudio,
		toggleScreen,
		toggleVideo,
	} = useMedia();

	// ************* Interview And Call Details *************
	const [callDetails, setCallDetails] =
		useState<TRegisterCallResponse | null>(null);
	const [interviewDetails, setInterviewDetails] =
		useState<TInterviewDetails | null>(null);
	// ************* +************************ *************

	// ************* Transcript And Question *************
	const [transcript, setTranscript] =
		useState<TTranscript | null>(null);
	const [questionStatement, setQuestionStatement] =
		useState<string>("");
	// ************* +********************** *************

	// ************* Call State *************
	const [showCallInfo, setShowCallInfo] =
		useState<boolean>(false);

	const [error, setError] = useState<string | null>(null);
	const [isLoading, setLoading] = useState<boolean>(false);
	// ************* +********** *************

	// ************* Refs *************
	const audioVisualizerRef =
		useRef<TAudioVisualizerRef | null>(null);
	const retellClientRef = useRef<RetellWebClient | null>(
		null
	);
	const backendSocketRef = useRef<WebSocket | null>(null);
	const pingIntervalRef = useRef<unknown>(null);
	const recordingBlobQueueRef =
		useRef<RecordingBlobQueue | null>(null);
	const mediaRecorderRef = useRef<MediaRecorder | null>(
		null
	);
	// ************* +**** *************

	const parseAndStoreUrl = () => {
		const assessmentId = location.pathname.split("/")[1];
		if (!assessmentId) {
			return;
		}

		const token = new URLSearchParams(location.search).get(
			"token"
		);
		if (!token) {
			return;
		}

		setAuthCookie({
			token,
		});

		return {
			assessmentId,
			token,
		};
	};

	const cleanUp = async () => {
		mediaCleanUp();
		pingIntervalRef.current &&
			clearInterval(pingIntervalRef.current as number);
		backendSocketRef.current?.close();
		await recordingBlobQueueRef.current?.stop();
		recordingBlobQueueRef.current = null;
		mediaRecorderRef.current?.removeEventListener(
			"dataavailable",
			onRecordingDataAvailable
		);
		mediaRecorderRef.current?.stop();
		setCallDetails(null);
	};

	const initializeWebClient = () => {
		const webClient = new RetellWebClient();
		retellClientRef.current = webClient;

		// Setup event listeners
		webClient.on("conversationStarted", () => {
			console.log("conversationStarted");
			setLoading(false);
		});

		webClient.on("audio", async (buffer: Uint8Array) => {
			if (audioVisualizerRef.current) {
				audioVisualizerRef.current.setBuffer(buffer);
			}
		});

		webClient.on(
			"conversationEnded",
			({ code, reason }) => {
				console.log(
					"Closed with code:",
					code,
					", reason:",
					reason
				);
				void cleanUp();
				setLoading(false);
			}
		);

		webClient.on("error", (error) => {
			console.error("An error occurred:", error);
			void cleanUp();
			setLoading(false);
		});

		webClient.on("update", (update) => {
			// Print live transcript as needed
			console.log("update", update);
			setTranscript(
				update.transcript[update.transcript.length - 1] as {
					role: string;
					content: string;
				}
			);
		});
	};

	const addBackendSocketListener = (callId?: string) => {
		if (!callId) return;
		const ws = new WebSocket(
			`${Config.WS_BASE_URL}/${callId}`
		);

		console.log("WS initialized");

		ws.onopen = () => {
			console.log("FRONTEND WS OPEN");
		};

		ws.onmessage = (event) => {
			console.log("FRONTEND WS MESSAGE:", event.data);
			const json = JSON.parse(event.data);
			// eslint-disable-next-line sonarjs/no-small-switch -- no need to refactor
			switch (json.eventType) {
				case "QUESTION_DATA": {
					const { shouldShow, content } = json.payload;
					console.log({ shouldShow, content });
					setQuestionStatement(shouldShow ? content : null);
					break;
				}
			}
		};

		ws.onerror = (error) => {
			console.error("FRONTEND WS ERROR:", error);
			notifyError("Error while establishing connection");
		};
		pingIntervalRef.current = setInterval(() => {
			ws.send("ping");
		}, 5000);
		backendSocketRef.current = ws;
	};

	const registerCall =
		async (): Promise<TRegisterCallResponse> => {
			try {
				if (
					!interviewDetails?.assessmentDetails.assessmentId
				) {
					notifyError("Assessment ID not found");
					throw new Error("Assessment ID not found");
				}
				await askPermissionsForMedia();
				addRemoveTrackListener();
				addDeviceChangeListener();

				return (
					await Services.interviewService.startInterview(
						interviewDetails.assessmentDetails.assessmentId
					)
				).data as TRegisterCallResponse;
			} catch (error) {
				console.log(error);
				void cleanUp();
				throw new Error(error as string);
			}
		};

	const onRecordingDataAvailable = useCallback(
		async (e: BlobEvent) => {
			console.log("onRecordingDataAvailable", e.data.size);
			recordingBlobQueueRef.current?.enqueue({
				blob: e.data,
				timestamp: new Date().toISOString(),
			});
		},
		[]
	);

	const startScreenShareRecording = (
		registerCallResponse: TRegisterCallResponse
	) => {
		if (!userScreenRef.current) return;
		if (
			!registerCallResponse.resumableSignedUrls
				.screenRecording
		)
			return;

		mediaRecorderRef.current = new MediaRecorder(
			userScreenRef.current,
			{
				mimeType: "video/webm",
				audioBitsPerSecond: 128000,
				videoBitsPerSecond: 3600000,
			}
		);
		recordingBlobQueueRef.current = new RecordingBlobQueue(
			registerCallResponse.resumableSignedUrls.screenRecording,
			mediaRecorderRef.current.mimeType
		);

		mediaRecorderRef.current.addEventListener(
			"dataavailable",
			onRecordingDataAvailable
		);
		mediaRecorderRef.current.start(5000);
	};

	const toggleConversation = async () => {
		if (!retellClientRef.current) return;

		if (callDetails?.callId) {
			setLoading(true);
			try {
				retellClientRef.current.stopConversation();
				await Services.interviewService.endInterview(
					callDetails.userDetails.assessmentUserTestId,
					callDetails.callId
				);
			} catch (error) {
				console.error(error);
				notifyError("Error while ending conversation");
			}
		} else {
			try {
				setLoading(true);
				const registerCallResponse = await registerCall();
				if (
					registerCallResponse.callId &&
					userMediaRef.current
				) {
					await retellClientRef.current
						.startConversation({
							callId: registerCallResponse.callId,
							sampleRate: registerCallResponse.sampleRate,
							enableUpdate: true,
							customStream: userMediaRef.current,
						})
						.catch(console.error);
					setCallDetails(registerCallResponse);
					addBackendSocketListener(
						registerCallResponse.callId
					);
					startScreenShareRecording(registerCallResponse);
				} else if (
					!(
						registerCallResponse as unknown as Record<
							string,
							boolean
						>
					).status
				) {
					notifyError(
						(
							registerCallResponse as unknown as Record<
								string,
								string
							>
						).msg
					);
				}
				setLoading(false);
			} catch (error) {
				console.error(error);
				notifyError("Error while starting conversation");
			}
		}
	};

	const fetchInterviewDetails = async (
		assessmentId?: string
	) => {
		if (!assessmentId) return;
		setLoading(true);
		try {
			const interviewDetails = (
				await Services.interviewService.validateCandidate(
					assessmentId
				)
			).data;
			if (
				!(interviewDetails as Record<string, boolean>)
					.status
			) {
				throw new Error(
					(interviewDetails as Record<string, string>).msg
				);
			}
			setInterviewDetails(
				interviewDetails as TInterviewDetails
			);
		} catch (error) {
			console.error(error);
			setError((error as Error).message);
			notifyError((error as Error).message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		setLoading(true);
		const { assessmentId } = parseAndStoreUrl() ?? {};
		initializeWebClient();
		void fetchInterviewDetails(assessmentId);

		return () => {
			cleanUp();
		};
	}, []);

	return (
		<CallContext.Provider
			value={{
				isCalling: !!callDetails,
				isAudioPlaying,
				isVideoPlaying,
				isScreenShared,
				toggleConversation,
				transcript,
				callDetails,
				audioVisualizerRef,
				userVideoElementRef,
				toggleAudio,
				toggleVideo,
				toggleScreen,
				showCallInfo,
				setShowCallInfo,
				interviewDetails,
				questionStatement,
				isLoading,
				askPermissionForScreenShare,
				userScreenRef,
			}}
		>
			{interviewDetails && !error ? children : null}
		</CallContext.Provider>
	);
};
