import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
	type ForwardRefExoticComponent,
	type RefAttributes,
} from "react";

import { useCall } from "../../hooks/useCall";

type TScreenShareDetectorProps = {
	height: number;
	width: number;
	onScreenShareDetected: () => void;
	onScreenShareNotDetected: () => void;
};

export type TScreenShareDetectorRefProps = {
	startScreenShareDetection: () => void;
	retryScreenShareDetection: () => void;
};

export const ScreenShareDetector: ForwardRefExoticComponent<
	TScreenShareDetectorProps &
		RefAttributes<TScreenShareDetectorRefProps>
> = forwardRef((props, ref) => {
	const videoRef = useRef<HTMLVideoElement>(null);
	const [isScreenShared, setScreenShared] = useState(false);
	const { askPermissionForScreenShare, userScreenRef } =
		useCall();

	const onScreenShareEnd = () => {
		alert("Screen sharing ended");
		unmount();
	};

	const startScreenShareDetection = async () => {
		try {
			await askPermissionForScreenShare();
			setScreenShared(true);
		} catch (error) {
			alert((error as Error).message);
			setTimeout(() => {
				unmount();
			}, 1000);
		}
		if (userScreenRef?.current) {
			videoRef.current!.srcObject = userScreenRef.current;
			userScreenRef.current
				.getVideoTracks()[0]
				.addEventListener("ended", onScreenShareEnd);
		}
		setTimeout(async () => {
			await videoRef.current!.play();
			props.onScreenShareDetected();
		}, 1000);
	};

	const unmount = () => {
		if (videoRef.current) {
			videoRef.current.srcObject = null;
		}
		setScreenShared(false);
		props.onScreenShareNotDetected();
	};

	useEffect(() => {
		return () => {
			unmount();
		};
	}, []);

	const retryScreenShareDetection = () => {
		unmount();
		void startScreenShareDetection();
	};

	useImperativeHandle(ref, () => ({
		startScreenShareDetection,
		retryScreenShareDetection,
	}));

	return (
		<div className="screen-share-detector">
			<video
				ref={videoRef}
				height={props.height}
				width={props.width}
				autoPlay
				muted
				playsInline
			/>
			<div className="buttons">
				<button
					onClick={startScreenShareDetection}
					disabled={isScreenShared}
				>
					Test Screen Sharing
				</button>
				{isScreenShared ? (
					<button onClick={retryScreenShareDetection}>
						Retry Sharing
					</button>
				) : null}
			</div>
		</div>
	);
});

ScreenShareDetector.displayName = "ScreenShareDetector";
