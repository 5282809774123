export const MediaConfig = {
	audio: {
		noiseSuppression: true,
		echoCancellation: true,
	},
	video: {
		noiseSuppression: true,
		echoCancellation: true,
	},
};
