import {
	TinyFaceDetectorOptions,
	createCanvasFromMedia,
	detectAllFaces,
	matchDimensions,
	nets,
	resizeResults,
} from "face-api.js";
import { DrawBox } from "face-api.js/build/commonjs/draw";
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
	type ForwardRefExoticComponent,
	type RefAttributes,
} from "react";

import { MediaConfig } from "../../configs/mediaConfig";

export type TFaceDetectorProps = {
	width: number;
	height: number;
	onFaceDetected: () => void;
	onFaceNotDetected: () => void;
};

export type TFaceDetectorRefProps = {
	startDetection: () => void;
	stopDetection: () => void;
};

export const FaceDetector: ForwardRefExoticComponent<
	TFaceDetectorProps & RefAttributes<TFaceDetectorRefProps>
> = forwardRef(
	(
		{ height, width, onFaceDetected, onFaceNotDetected },
		ref
	) => {
		const videoRef = useRef<HTMLVideoElement>(null);
		const containerRef = useRef<HTMLDivElement>(null);
		const canvasRef = useRef<HTMLCanvasElement>();
		const streamRef = useRef<MediaStream | null>(null);

		const [isLoading, setLoading] = useState(false);
		const [isVideoPlaying, setVideoPlaying] =
			useState(false);

		const load = async () => {
			setLoading(true);

			try {
				const modelUri = "/models";
				await Promise.all([
					nets.tinyFaceDetector.loadFromUri(modelUri),
				]);

				streamRef.current =
					await navigator.mediaDevices.getUserMedia({
						video: MediaConfig.video,
						audio: false,
					});
				setTimeout(async () => {
					videoRef.current!.srcObject = streamRef.current;
					await videoRef.current!.play();
					setVideoPlaying(true);
				}, 1000);
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		};

		const unload = () => {
			try {
				nets.tinyFaceDetector.dispose(false);
				streamRef.current?.getTracks().forEach((track) => {
					track.stop();
					track.enabled = false;
					streamRef.current?.removeTrack(track);
				});
				console.log("Unloading models");

				if (videoRef.current) {
					videoRef.current.srcObject = null;
				}
				if (canvasRef.current) {
					canvasRef.current.remove();
				}
				setVideoPlaying(false);
			} catch (error) {
				console.error(error);
			}
		};

		useEffect(() => {
			return () => {
				unload();
			};
		}, []);

		const createCanvas = async () => {
			if (!videoRef.current) return;
			try {
				canvasRef.current = createCanvasFromMedia(
					videoRef.current
				);
			} catch (error) {
				console.error(error);
				await new Promise((resolve) =>
					setTimeout(resolve, 100)
				);
				await createCanvas();
			}
		};

		const handleVideoOnPlay = async () => {
			console.log("Video playing");

			if (videoRef.current) {
				if (!canvasRef.current) {
					await createCanvas();

					canvasRef.current!.style.position = "absolute";
					containerRef.current?.appendChild(
						canvasRef.current!
					);
				}

				const displaySize = {
					width,
					height,
				};

				matchDimensions(canvasRef.current!, displaySize);

				setInterval(async () => {
					if (!videoRef.current) return;
					const detections = await detectAllFaces(
						videoRef.current,
						new TinyFaceDetectorOptions()
					);
					if (!canvasRef.current) return;
					const resizedDetections = resizeResults(
						detections,
						displaySize
					);

					canvasRef.current
						.getContext("2d")
						?.clearRect(0, 0, width, height);

					let maxScoreDetection = resizedDetections.length
						? resizedDetections[0]
						: null;
					resizedDetections.forEach((detection, index) => {
						if (
							!maxScoreDetection ||
							detection.score > maxScoreDetection.score
						) {
							maxScoreDetection = detection;
						}
					});

					if (!maxScoreDetection) return;
					new DrawBox(maxScoreDetection.box, {
						lineWidth: 1,
					}).draw(canvasRef.current);

					if (detections.length === 1) {
						onFaceDetected();
					} else {
						onFaceNotDetected();
					}
				}, 25);
			}
		};

		useImperativeHandle(ref, () => ({
			startDetection: load,
			stopDetection: unload,
		}));

		return (
			<div className="face-detector">
				<div
					ref={containerRef}
					className="user-video"
				>
					{isLoading ? <div>Loading...</div> : null}
					<video
						ref={videoRef}
						height={height}
						width={width}
						onPlay={handleVideoOnPlay}
						style={{ borderRadius: "10px" }}
					/>
				</div>
				<button
					onClick={load}
					disabled={isVideoPlaying}
				>
					Test Camera
				</button>
			</div>
		);
	}
);

FaceDetector.displayName = "FaceDetector";
