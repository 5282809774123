// import { GUI } from 'dat.gui';
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	type ForwardRefExoticComponent,
	type RefAttributes,
} from "react";
import {
	AudioListener,
	Clock,
	Color,
	IcosahedronGeometry,
	Mesh,
	PerspectiveCamera,
	SRGBColorSpace,
	Scene,
	ShaderMaterial,
	Vector2,
	WebGLRenderer,
} from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { OutputPass } from "three/examples/jsm/postprocessing/OutputPass";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";

import { FragmentShader } from "../constants/fragmentShader";
import { VertexShader } from "../constants/vertexShader";

type TAudioVisualizerProps = {
	height?: number;
	width?: number;
	// audioNode: AudioWorkletNode;
};

export type TAudioVisualizerRef = {
	setBuffer: (buffer: Uint8Array) => void;
};

export const AudioVisualizer: ForwardRefExoticComponent<
	TAudioVisualizerProps & RefAttributes<TAudioVisualizerRef>
> = forwardRef(
	(
		{
			height = window.innerHeight,
			width = window.innerWidth,
		},
		ref
	) => {
		const averageFrequencyRef = useRef<number>(0);
		const containerRef = useRef<HTMLDivElement>(null);

		const init = () => {
			const renderer = new WebGLRenderer({
				alpha: true,
				antialias: true,
				precision: "highp",
			});
			renderer.setSize(width, height);
			renderer.pixelRatio = 1;
			containerRef.current?.appendChild(
				renderer.domElement
			);
			containerRef.current?.style.setProperty(
				"transform",
				"scale(0.5)"
			);
			renderer.outputColorSpace = SRGBColorSpace;

			const scene = new Scene();
			scene.background = new Color(0x161b1d);
			const camera = new PerspectiveCamera(
				50,
				width / height,
				1,
				5000
			);

			const params = {
				red: 0.5,
				green: 0.5,
				blue: 1.0,
				threshold: 0.25,
				strength: 0.25,
				radius: 0.1,
			};

			const renderScene = new RenderPass(scene, camera);
			const bloomPass = new UnrealBloomPass(
				new Vector2(width, height),
				params.strength,
				params.radius,
				params.threshold
			);

			const bloomComposer = new EffectComposer(renderer);
			bloomComposer.addPass(renderScene);
			bloomComposer.addPass(bloomPass);

			const outputPass = new OutputPass();
			bloomComposer.addPass(outputPass);

			camera.position.set(0, -2, 14);
			camera.lookAt(0, 0, 0);

			const uniforms = {
				u_time: { type: "f", value: 1 },
				u_frequency: { type: "f", value: 1 },
				u_red: { type: "f", value: params.red },
				u_green: { type: "f", value: params.green },
				u_blue: { type: "f", value: params.blue },
			};

			const mat = new ShaderMaterial({
				uniforms,
				vertexShader: VertexShader,
				fragmentShader: FragmentShader,
			});

			const geo = new IcosahedronGeometry(2, 24);
			const mesh = new Mesh(geo, mat);
			scene.add(mesh);
			mesh.material.wireframe = true;
			mesh.material.alphaHash = true;
			mesh.material.clipIntersection = true;
			mesh.material.wireframeLinewidth = 10;
			mesh.material.linewidth = 10;

			const listener = new AudioListener();
			camera.add(listener);

			const clock = new Clock();
			function animate() {
				camera.lookAt(scene.position);

				uniforms.u_time.value = clock.getElapsedTime();
				uniforms.u_frequency.value =
					averageFrequencyRef.current;
				bloomComposer.render();
				requestAnimationFrame(animate);
			}

			animate();

			return () => {
				renderer.dispose();
			};
		};

		useImperativeHandle(ref, () => ({
			setBuffer: (buffer: Uint8Array) => {
				const average =
					buffer.reduce((acc, val) => acc + val, 0) /
					buffer.length;

				averageFrequencyRef.current = average;
			},
		}));

		useEffect(() => {
			const cleanUp = init();
			return () => {
				cleanUp();
			};
		}, []);

		return <div ref={containerRef} />;
	}
);

AudioVisualizer.displayName = "AudioVisualizer";
