type TLink<T> = QueueNode<T> | null;
class QueueNode<T> {
	data: T;
	next: TLink<T>;

	constructor(data: T) {
		this.data = data;
		this.next = null;
	}
}

const RETRY_COUNT_LIMIT = 3;

class Queue<T> {
	private front: TLink<T>;
	private rear: TLink<T>;
	size: number;

	constructor() {
		this.front = this.rear = null;
		this.size = 0;
	}

	get isEmpty() {
		return this.size === 0;
	}

	enqueue(data: T) {
		if (this.isEmpty) {
			this.front = this.rear = new QueueNode(data);
		} else {
			this.rear!.next = new QueueNode(data);
			this.rear = this.rear!.next;
		}
		this.size++;
	}

	dequeue() {
		if (this.isEmpty) {
			return null;
		}
		const itemToBeRemoved = this.front;

		if (this.front === this.rear) {
			this.rear = null;
		}
		this.front = this.front!.next;
		this.size--;

		return itemToBeRemoved;
	}

	peek() {
		if (this.isEmpty) {
			return null;
		}

		return this.front!.data;
	}
}

type TBlobQueueNode = {
	blob: Blob;
	timestamp: string;
};

const CHUNK_SIZE = 262144;

export class RecordingBlobQueue extends Queue<TBlobQueueNode> {
	private stopSendingBlob: boolean;
	private retryCount: number;
	private readonly uploadURI: string;
	private totalUploaded: number;
	mediaType: string;
	onError?: (
		e: Error,
		remainingBlobs: Array<TBlobQueueNode>
	) => void;

	shorterBlob: Blob | null;

	constructor(
		uploadURI: string,
		mediaType: string,
		onError?: (
			e: Error,
			remainingBlobs: Array<TBlobQueueNode>
		) => void
	) {
		super();
		this.retryCount = 0;
		this.stopSendingBlob = false;
		this.mediaType = mediaType;
		void this.startSendingBlob();
		this.onError = onError;
		this.totalUploaded = 0;
		this.shorterBlob = null;
		this.uploadURI = uploadURI;
	}

	enqueue(data: TBlobQueueNode): void {
		if (this.shorterBlob) {
			// concat the shorter blob with the current blob
			const blob = new Blob([this.shorterBlob, data.blob], {
				type: data.blob.type,
			});

			this.shorterBlob = null;
			this.enqueue({ blob, timestamp: data.timestamp });

			return;
		}

		if (data.blob.size < 262144) {
			this.shorterBlob = data.blob;

			return;
		}

		const sizeByChunkSize = data.blob.size / CHUNK_SIZE;

		// if the blob size is a multiple of chunk size, enqueue it as it is
		if (Number.isInteger(sizeByChunkSize)) {
			super.enqueue(data);
		} else {
			// else enqueue the first chunk and store the second chunk in shorterBlob
			const end = CHUNK_SIZE * Math.floor(sizeByChunkSize);
			const firstBlob = data.blob.slice(0, end);
			const secondBlob = data.blob.slice(end);

			this.shorterBlob = secondBlob;
			super.enqueue({
				blob: firstBlob,
				timestamp: data.timestamp,
			});
		}
	}

	async startSendingBlob() {
		try {
			this.retryCount = 0;
			while (!this.stopSendingBlob) {
				if (this.isEmpty) {
					await new Promise((resolve) =>
						setTimeout(resolve, 500)
					);

					continue;
				}
				try {
					await this.sendBlob(this.peek()!);
					this.dequeue();
				} catch (error) {
					console.error(error);
					if (this.retryCount > RETRY_COUNT_LIMIT) {
						this.onError?.(
							error as Error,
							this.dequeueAll()
						);
						this.stopSendingBlob = true;
					} else {
						this.retryCount++;
						console.error(`retrying ${this.retryCount}`);
					}
				}
			}
		} catch (error) {
			this.dequeueAll();
			this.enqueue = () => null;
			this.onError?.(error as Error, []);
		}
	}

	dequeueAll() {
		const blobs: Array<TBlobQueueNode> = [];

		while (!this.isEmpty) {
			blobs.push(this.dequeue()!.data);
		}

		return blobs;
	}

	async sendBlob(blobNode: TBlobQueueNode) {
		const buffer = await blobNode.blob.arrayBuffer();

		const size = blobNode.blob.size;
		const range = `bytes ${this.totalUploaded}-${
			this.totalUploaded + size - 1
		}/*`;

		await Promise.allSettled([
			fetch(this.uploadURI, {
				body: buffer,
				method: "PUT",
				headers: {
					"Content-Length": `${size}`,
					"Content-Range": range,
					"Content-Type": "video/webm",
				},
			}),
		]);

		this.totalUploaded += blobNode.blob.size;
	}

	async stop() {
		let count = 0;
		while (!this.isEmpty && count < RETRY_COUNT_LIMIT) {
			await new Promise((resolve) =>
				setTimeout(resolve, 500)
			);

			count++;
		}
		this.stopSendingBlob = true;
	}
}
