import { useState, type FC } from "react";

import { useCall } from "../hooks/useCall";

import { UserConsent } from "./UserConsent";

export const PreCallScreen: FC = () => {
	const { interviewDetails, toggleConversation } =
		useCall();

	const [showUserConsent, setShowUserConsent] =
		useState(false);

	if (!interviewDetails) return null;

	return (
		<>
			<div className="pre-call-screen">
				<h1 className="company">
					{interviewDetails.assessmentDetails.company}
				</h1>
				<p>{interviewDetails.assessmentDetails.role}</p>
				<div className="user-details">
					<h1 className="user-name">
						{interviewDetails.userDetails.name}
					</h1>
					<p className="user-email">
						{interviewDetails.userDetails.email}
					</p>
				</div>
				<button
					className="button-with-animation"
					onClick={() => {
						setShowUserConsent(true);
					}}
				>
					Start Call
				</button>
				{showUserConsent ? (
					<UserConsent
						onUserConsentComplete={() => {
							setShowUserConsent(false);
							toggleConversation();
						}}
					/>
				) : null}
			</div>
		</>
	);
};
