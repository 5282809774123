import { type FC } from "react";
import ReactMarkdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

type TMarkdownProps = {
	children: string;
	className?: string;
};

export const RenderMarkdown: FC<TMarkdownProps> = ({
	children,
	className,
}) => {
	return (
		<ReactMarkdown
			className={`markdown-body ${className ?? ""}`}
			remarkPlugins={[remarkGfm]}
			rehypePlugins={[rehypeHighlight, rehypeRaw]}
		>
			{children}
		</ReactMarkdown>
	);
};
