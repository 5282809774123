import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// toast.configure();

function notifySuccess(msg: string) {
	toast.success(msg, {
		position: "top-right",
		autoClose: 2000,
	});
}

const notifyEmail = (msg: string) => {
	toast.success(msg, {
		position: "top-right",
	});
};

const notifyError = (msg: string) => {
	toast.error(msg, {
		position: "top-right",
		autoClose: 2000,
	});
};

const notifyWarning = (msg: string) => {
	toast.warning(msg, {
		position: "top-right",
		autoClose: 2000,
	});
};

const notifyInfo = (msg: string) => {
	toast.info(msg, {
		position: "top-right",
		autoClose: 2000,
	});
};

export {
	notifyEmail,
	notifyError,
	notifyInfo,
	notifySuccess,
	notifyWarning,
};
