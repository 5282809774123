import { type FC } from "react";
import { FaInfo } from "react-icons/fa";
import { FiX } from "react-icons/fi";

import { useCall } from "../hooks/useCall";

export const CallInfoButton: FC = (props) => {
	const call = useCall();
	return (
		<div className="call-info-buttons">
			<button
				onClick={() => {
					call.setShowCallInfo((prev) => !prev);
				}}
			>
				{call.showCallInfo ? <FiX /> : <FaInfo />}
			</button>
		</div>
	);
};
