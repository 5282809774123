import { useState, type FC } from "react";
import { FaUser } from "react-icons/fa";

import { useCall } from "../hooks/useCall";

export const UserVideo: FC = () => {
	const call = useCall();
	const [collapsed, setCollapsed] = useState(true);

	return (
		<div
			className="user-video"
			onClick={() => {
				setCollapsed((prev) => !prev);
			}}
		>
			<video
				id="userVideo"
				ref={call.userVideoElementRef}
				autoPlay
				playsInline
				muted
				style={
					call.isCalling
						? {
								height: collapsed ? 125 : 250,
								width: collapsed ? 150 : 300,
						  }
						: { height: 0, width: 0, visibility: "hidden" }
				}
			/>
			{call.isVideoPlaying ? (
				<div className="avatar">
					<FaUser
						color="white"
						size={48}
					/>
				</div>
			) : null}
		</div>
	);
};
