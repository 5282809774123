import {
	useEffect,
	useRef,
	useState,
	type FC,
} from "react";

import { CameraTest } from "./CameraTest";
import { Disclaimer } from "./Disclaimer";
import { MicTest } from "./MicTest";
import { ScreenShareTest } from "./ScreenShareTest";

enum UserConsentStateEnum {
	MIC_TEST,
	CAMERA_TEST,
	SCREEN_TEST,
	DISCLAIMER,
}

type TUserConsentProps = {
	onUserConsentComplete: () => void;
};

export const UserConsent: FC<TUserConsentProps> = ({
	onUserConsentComplete,
}) => {
	const [userConsentState, setUserConsentState] =
		useState<UserConsentStateEnum>(
			UserConsentStateEnum.MIC_TEST
		);

	const containerRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		(
			containerRef.current?.childNodes.item(
				userConsentState
			) as HTMLElement | null
		)?.scrollIntoView({
			behavior: "smooth",
			block: "center",
		});
	}, [userConsentState]);

	return (
		<div className="user-consent-modal">
			<div
				className="user-consent-container"
				ref={containerRef}
			>
				<MicTest
					nextButtonTitle="Next"
					onMicTestComplete={() => {
						setUserConsentState(
							UserConsentStateEnum.CAMERA_TEST
						);
					}}
				/>
				<CameraTest
					nextButtonTitle="Next"
					onCameraTestComplete={() => {
						setUserConsentState(
							UserConsentStateEnum.SCREEN_TEST
						);
					}}
				/>
				<ScreenShareTest
					nextButtonTitle="Next"
					onScreenShareTestComplete={() => {
						setUserConsentState(
							UserConsentStateEnum.DISCLAIMER
						);
					}}
				/>
				<Disclaimer onAccept={onUserConsentComplete} />
			</div>
		</div>
	);
};
/**
<FaceDetector
	height={500}
	width={250}
/>
<AudioDetector
	height={250}
	width={500}
/>
 */
