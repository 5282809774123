import { AudioVisualizer } from "./components/AudioVisualizer";
import { CallInfoButton } from "./components/CallInfoButton";
import { CallInfoSideBar } from "./components/CallInfoSideBar";
import { Clock } from "./components/Clock";
import { Loader } from "./components/Loader";
import { MediaControls } from "./components/MediaControls";
import { PreCallScreen } from "./components/PreCallScreen";
import { QuestionContainer } from "./components/QuestionContainer";
import { UserVideo } from "./components/UserVideo";
import { useCall } from "./hooks/useCall";
import "./styles/index.scss";
import { capitalize } from "./utils/common";

const App = () => {
	const call = useCall();

	return (
		<div className="App">
			<div className="meeting-container">
				{call.isLoading ? (
					<div className="loader">
						<Loader />
					</div>
				) : null}
				<div className="main">
					{call.isCalling ? (
						<>
							<QuestionContainer
								questionStatement={call.questionStatement}
							/>
							<div className="clock">
								<Clock />
							</div>
							{call.transcript ? (
								<div className="transcript">
									<div
										className="transcript-role
						"
									>
										{capitalize(call.transcript.role)}:
									</div>
									<div
										className="transcript-content
						"
									>
										{call.transcript.content}
									</div>
								</div>
							) : null}
							<MediaControls />
							<CallInfoButton />
						</>
					) : (
						<PreCallScreen />
					)}
				</div>
				{call.showCallInfo ? <CallInfoSideBar /> : null}
				<UserVideo />
				{call.isCalling ? (
					<div className="audio-visualizer">
						<AudioVisualizer
							ref={call.audioVisualizerRef}
							height={1000}
							width={1000}
						/>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default App;
