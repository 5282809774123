import Cookies from "js-cookie";

type TSetAuthCookieArgs = {
	token: string;
	domain?: string;
	expires?: number;
};

const authCookieName = "accio-interview-token";

export const setAuthCookie = ({
	token,
	domain,
	expires = 365,
}: TSetAuthCookieArgs) => {
	if (!domain) {
		const localStorageDomain =
			localStorage.getItem("domain");
		if (localStorageDomain) {
			domain = localStorageDomain;
		} else {
			domain = window.location.hostname;
		}
	}
	Cookies.set(authCookieName, token, {
		domain,
		expires,
		secure: true,
	});
	localStorage.setItem(authCookieName, token);
};

export const getAuthCookie = () => {
	return (
		Cookies.get(authCookieName) ??
		localStorage.getItem(authCookieName)
	);
};

export const removeAuthCookie = () => {
	let domain;
	const localStorageDomain = localStorage.getItem("domain");
	if (localStorageDomain) {
		domain = localStorageDomain;
	} else {
		domain = window.location.hostname;
	}
	Cookies.remove(authCookieName, {
		domain,
	});
	localStorage.getItem(authCookieName);
};
