import { type FC } from "react";

type TCallEndModalProps = {
	open: boolean;
	onConfirm: () => void;
	onDecline: () => void;
};

export const CallEndModal: FC<TCallEndModalProps> = (
	props
) => {
	if (!props.open) return null;

	return (
		<div className="call-end-modal">
			<div className="content">
				<label className="heading">Are you sure?</label>
				<p className="description">
					You will not be able to join the call again.
				</p>

				<div className="buttons">
					<button
						onClick={props.onConfirm}
						className="yes"
					>
						Yes
					</button>
					<button
						onClick={props.onDecline}
						className="no"
					>
						Cancel
					</button>
				</div>
			</div>
		</div>
	);
};
