import { useRef, useState, type FC } from "react";

import {
	AudioDetector,
	type TAudioDetectorRefProps,
} from "./AudioDetector";

type TMicTestProps = {
	onMicTestComplete: () => void;
	nextButtonTitle: string;
};

export const MicTest: FC<TMicTestProps> = (props) => {
	const audioDetectorRef =
		useRef<TAudioDetectorRefProps>(null);
	const [disableNext, setDisableNext] = useState(true);

	return (
		<div className="mic-test consent-state">
			<h2 className="heading"> Mic Testing </h2>
			<div className="content">
				<ol>
					<li>
						Click on "Test Mic" button to begin mic test.
					</li>
					<li>
						Say something and check if there is any change
						in the wave.
					</li>
					<li>
						If there is no change in the wave, please check
						your microphone settings.
					</li>
					<li>Click on "Next" button to proceed.</li>
				</ol>
				<div className="bottom-row">
					<AudioDetector
						height={100}
						width={250}
						ref={audioDetectorRef}
						onAudioDetected={() => {
							setDisableNext(false);
						}}
					/>
					<div className="buttons">
						<button
							disabled={disableNext}
							onClick={() => {
								audioDetectorRef.current?.stopListening();
								props.onMicTestComplete();
							}}
						>
							{props.nextButtonTitle}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
