import { type FC } from "react";
import Lottie from "react-lottie-player";

import loader from "../assets/lotties/loader.json";

export const Loader: FC = () => {
	return (
		<Lottie
			loop
			animationData={loader}
			play
			style={{ height: "300px" }}
			speed={1.75}
		/>
	);
};
