import { useRef, useState, type FC } from "react";

import {
	FaceDetector,
	type TFaceDetectorRefProps,
} from "./FaceDetector";

type TCameraTestProps = {
	onCameraTestComplete: () => void;
	nextButtonTitle: string;
};

export const CameraTest: FC<TCameraTestProps> = (props) => {
	const faceDetectorRef =
		useRef<TFaceDetectorRefProps>(null);
	const [disableNext, setDisableNext] = useState(true);
	return (
		<div className="camera-test consent-state">
			<h2> Camera Testing </h2>
			<div className="content">
				<ol>
					<li>Click on "Test Camera" to proceed.</li>
					<li>
						Please sit in a well-lit room to ensure that
						your face is clearly visible.
					</li>
					<li>Ensure you face detected in the camera.</li>
					<li>
						If your camera is not working, please check your
						camera settings.
					</li>
					<li>Click on "Next" button to proceed.</li>
				</ol>
				<div className="bottom-row">
					<FaceDetector
						height={250}
						width={250}
						onFaceDetected={() => {
							setDisableNext(false);
						}}
						onFaceNotDetected={() => {
							setDisableNext(true);
						}}
						ref={faceDetectorRef}
					/>
					<div className="buttons">
						<button
							disabled={disableNext}
							onClick={() => {
								faceDetectorRef.current?.stopDetection();
								props.onCameraTestComplete();
							}}
						>
							{props.nextButtonTitle}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
