import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
	type ForwardRefExoticComponent,
	type RefAttributes,
} from "react";

import { MediaConfig } from "../../configs/mediaConfig";

export type TAudioDetectorProps = {
	height: number;
	width: number;
	onAudioDetected: () => void;
};

export type TAudioDetectorRefProps = {
	startListening: () => void;
	stopListening: () => void;
};

type TAudioDetectorVisualizerRefProps = {
	setAudioData: (audioData: Uint8Array) => void;
};

export const AudioDetector: ForwardRefExoticComponent<
	TAudioDetectorProps &
		RefAttributes<TAudioDetectorRefProps>
> = forwardRef((props, ref) => {
	const audioDetectorVisualizerRef =
		useRef<TAudioDetectorVisualizerRefProps>(null);

	const rafId = useRef<number>(0);
	const sourceRef =
		useRef<MediaStreamAudioSourceNode | null>(null);
	const analyserRef = useRef<AnalyserNode | null>(null);
	const audioStreamRef = useRef<MediaStream | null>(null);

	const [isListening, setIsListening] = useState(false);

	const listenToAudio = async () => {
		const audioContext = new AudioContext();

		analyserRef.current = audioContext.createAnalyser();
		const dataArray = new Uint8Array(
			analyserRef.current.frequencyBinCount
		);
		audioStreamRef.current =
			await navigator.mediaDevices.getUserMedia({
				audio: MediaConfig.audio,
				video: false,
			});
		const source = audioContext.createMediaStreamSource(
			audioStreamRef.current
		);

		source.connect(analyserRef.current);
		setIsListening(true);

		const tick = () => {
			analyserRef.current!.getByteTimeDomainData(dataArray);
			audioDetectorVisualizerRef.current?.setAudioData(
				dataArray
			);
			if (dataArray.some((item) => item > 0)) {
				props.onAudioDetected();
			}
			rafId.current = requestAnimationFrame(tick);
		};

		rafId.current = requestAnimationFrame(tick);
	};

	const stopListening = () => {
		cancelAnimationFrame(rafId.current);
		analyserRef.current?.disconnect();
		sourceRef.current?.disconnect();
		audioStreamRef.current?.getTracks().forEach((track) => {
			track.stop();
		});
		setIsListening(false);
	};

	useEffect(() => {
		return () => {
			stopListening();
		};
	}, []);

	useImperativeHandle(ref, () => ({
		startListening: listenToAudio,
		stopListening,
	}));

	return (
		<div className="audio-detector">
			<AudioDetectorVisualizer
				{...props}
				ref={audioDetectorVisualizerRef}
			/>
			<button
				onClick={listenToAudio}
				disabled={isListening}
			>
				Test Mic
			</button>
		</div>
	);
});

const AudioDetectorVisualizer: ForwardRefExoticComponent<
	TAudioDetectorProps &
		RefAttributes<TAudioDetectorVisualizerRefProps>
> = forwardRef(({ height, width }, ref) => {
	const canvasRef = useRef<HTMLCanvasElement>(null);

	const draw = (audioData: Uint8Array) => {
		const canvas = canvasRef.current;
		if (!canvas) {
			return;
		}
		const height = canvas.height;
		const width = canvas.width;
		const context = canvas.getContext("2d");
		if (!context) {
			return;
		}

		let x = 0;
		const sliceWidth = (width * 1.0) / audioData.length;

		context.lineWidth = 2;
		context.strokeStyle = "white";
		context.clearRect(0, 0, width, height);

		context.beginPath();
		context.moveTo(0, height / 2);
		for (const item of audioData) {
			const y = (item / 255.0) * height;
			context.lineTo(x, y);
			x += sliceWidth;
		}
		context.lineTo(x, height / 2);
		context.stroke();
	};

	useEffect(() => {
		const arr = new Uint8Array(255);
		arr.fill(256 / 2);
		draw(arr);
	}, []);

	const setAudioData = (audioData: Uint8Array) => {
		draw(audioData);
	};

	useImperativeHandle(ref, () => ({
		setAudioData,
	}));

	return (
		<canvas
			height={height}
			width={width}
			ref={canvasRef}
		/>
	);
});

AudioDetector.displayName = "AudioDetector";
AudioDetectorVisualizer.displayName =
	"AudioDetectorVisualizer";
