import { type FC, type ReactNode } from "react";

import { CallProvider } from "./CallContext";

type TContextsProps = {
	children: ReactNode;
};

export const Contexts: FC<TContextsProps> = (props) => {
	return <CallProvider>{props.children}</CallProvider>;
};
