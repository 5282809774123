import { type FC } from "react";

import { useCall } from "../hooks/useCall";

export const CallInfoSideBar: FC = () => {
	const call = useCall();

	// if (!call.callDetails) return null;

	return (
		<div className="call-info-side-bar">
			<div className="content">
				<div className="interview-details group">
					<div className="company">
						<label>Company: </label>
						{
							call.interviewDetails?.assessmentDetails
								.company
						}
					</div>
					<div className="role">
						<label>Role Offering: </label>
						{call.interviewDetails?.assessmentDetails.role}
					</div>
				</div>
				<div className="user-details group">
					<div className="user-name">
						<label>Name: </label>{" "}
						{call.interviewDetails?.userDetails.name}
					</div>
					<div className="user-email">
						<label>Email: </label>{" "}
						{call.interviewDetails?.userDetails.email}
					</div>
				</div>

				<div className="id">
					<label>Interview ID: </label>
					{
						call.interviewDetails?.assessmentDetails
							.assessmentId
					}
				</div>
			</div>
		</div>
	);
};
