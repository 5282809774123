import { useState, type FC } from "react";

import { ScreenShareDetector } from "./ScreenShareDetector";

type TScreenScreenTestProps = {
	onScreenShareTestComplete: () => void;
	nextButtonTitle: string;
};

export const ScreenShareTest: FC<TScreenScreenTestProps> = (
	props
) => {
	const [disableNext, setDisableNext] = useState(true);
	return (
		<div className="screen-share-test consent-state">
			<h2>Screen Share Testing</h2>
			<div className="content">
				<ol>
					<li>
						Click on "Start Sharing" button to begin screen
						share test.
					</li>
					<li>
						Please select "Entire Screen" option to proceed.
					</li>
					<li>Check if your screen is shared properly.</li>
					<li>Click on "Next" button to proceed.</li>
				</ol>
				<div className="bottom-row">
					<ScreenShareDetector
						height={200}
						width={
							(192.5 * window.outerWidth) /
							window.outerHeight
						}
						onScreenShareDetected={() => {
							setDisableNext(false);
						}}
						onScreenShareNotDetected={() => {
							setDisableNext(true);
						}}
					/>
					<div className="buttons">
						<button
							disabled={disableNext}
							onClick={props.onScreenShareTestComplete}
						>
							{props.nextButtonTitle}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
