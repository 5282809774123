import axios, {
	type AxiosError,
	type AxiosRequestConfig,
	type AxiosResponse,
} from "axios";

import { getAuthCookie } from "../utils/authCookie";

export type THandleStatusCode = Record<
	number,
	(error: AxiosError) => void
>;

export const ApiFailureErrorMessage = "Failure in API call";

export const HttpService = {
	getToken() {
		try {
			return getAuthCookie();
		} catch (error) {
			return "";
		}
	},

	async get<T>(
		url: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<T>> {
		return await this.request<T>({
			method: "GET",
			url,
			...config,
		});
	},

	async post<T>(
		url: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<T>> {
		return await this.request<T>({
			method: "POST",
			url,
			...config,
		});
	},

	async put<T>(
		url: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<T>> {
		return await this.request<T>({
			method: "PUT",
			url,
			...config,
		});
	},

	async getWithoutToken<T>(
		url: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<T>> {
		return await this.requestWithoutToken<T>({
			method: "GET",
			url,
			...config,
		});
	},

	async postWithoutToken<T>(
		url: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<T>> {
		return await this.requestWithoutToken<T>({
			method: "POST",
			url,
			...config,
		});
	},

	async putWithoutToken<T>(
		url: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<T>> {
		return await this.requestWithoutToken<T>({
			method: "PUT",
			url,
			...config,
		});
	},

	async request<T>(
		config: AxiosRequestConfig
	): Promise<AxiosResponse<T>> {
		try {
			const token = this.getToken();
			const defaultRequestInfo: AxiosRequestConfig = {
				headers: {
					maxContentLength: Infinity,
					maxBodyLength: Infinity,
					"acciomatrix-token": token,
				},
			};
			return await axios.request<T>({
				...defaultRequestInfo,
				...config,
				headers: {
					...defaultRequestInfo.headers,
					...config.headers,
				},
			});
		} catch (e: unknown) {
			throw this.handleHttpError(e);
		}
	},

	async requestWithoutToken<T>(
		config: AxiosRequestConfig
	): Promise<AxiosResponse<T>> {
		const defaultRequestInfo: AxiosRequestConfig = {
			headers: {
				maxContentLength: Infinity,
				maxBodyLength: Infinity,
			},
		};
		return await axios.request<T>({
			...defaultRequestInfo,
			...config,
			headers: {
				...defaultRequestInfo.headers,
				...config.headers,
			},
		});
	},

	onHttpErrorStatus(
		error: AxiosError,
		handleStatusCode: THandleStatusCode
	): void {
		if (error.response?.status) {
			const statusCode = error.response.status;
			const handleStatusCodeFn =
				handleStatusCode[statusCode];

			if (typeof handleStatusCodeFn === "function") {
				handleStatusCodeFn(error);
			}
		}
	},

	handleHttpError(error: unknown) {
		const errorMessage = (
			(error as AxiosError).response?.data as
				| Record<string, string>
				| undefined
				| null
		)?.message;
		if (errorMessage) {
			return Error(errorMessage);
		} else {
			return Error(ApiFailureErrorMessage);
		}
	},
};
