import { type FC } from "react";

import { RenderMarkdown } from "./RenderMarkdown";

type TQuestionContainerPropsType = {
	questionStatement?: string;
};

export const QuestionContainer: FC<
	TQuestionContainerPropsType
> = (props) => {
	if (!props.questionStatement) {
		return null;
	}
	return (
		<div className="question-container">
			<RenderMarkdown>
				{props.questionStatement}
			</RenderMarkdown>
		</div>
	);
};
