import { type FC, type ReactNode } from "react";

type TSwitchProps = {
	name: string;
	checked: boolean;
	onChange: () => void;
	checkedColor?: string;
	uncheckedColor?: string;
	checkedIcon?: ReactNode;
	uncheckedIcon?: ReactNode;
};

export const Switch: FC<TSwitchProps> = (props) => {
	return (
		<>
			<input
				checked={props.checked}
				onChange={props.onChange}
				type="checkbox"
				id={`react-switch-new-${props.name}`}
				className="react-switch-checkbox"
			/>
			<label
				className="react-switch-label"
				htmlFor={`react-switch-new-${props.name}`}
			>
				<span
					className={"react-switch-button"}
					style={{
						background: props.checked
							? props.checkedColor
							: props.uncheckedColor,
					}}
				>
					{props.checked
						? props.checkedIcon
						: props.uncheckedIcon}
				</span>
			</label>
		</>
	);
};
