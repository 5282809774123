import { type FC } from "react";

type TDisclaimerProps = {
	onAccept: () => void;
};

export const Disclaimer: FC<TDisclaimerProps> = (props) => {
	return (
		<div className="disclaimer consent-state">
			<h2 className="heading"> Disclaimer </h2>
			<div className="content">
				<ol className="points">
					<li>
						You should not turn off your camera and
						microphone at any point of the time during the
						test.
					</li>
					<li>
						You should not stop sharing your screen at any
						point of time during the test.
					</li>
					<li>
						Violation of any of the above rules will be
						considered as a failure as per the code of
						conduct.
					</li>
					<li>
						By clicking on the accept button, you agree to
						the above rules.
					</li>
				</ol>
			</div>
			<div
				className="buttons"
				style={{ flexDirection: "column" }}
			>
				<button
					onClick={props.onAccept}
					style={{
						alignSelf: "flex-end",
					}}
				>
					Accept
				</button>
			</div>
		</div>
	);
};
