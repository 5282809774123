import { Config } from "../configs";

import { HttpService } from "./http.service";

export const InterviewService = {
	async startInterview(assessmentId: string) {
		const url = `${Config.BACKEND_BASE_URL}/assessment-user-test/start-interview`;
		return await HttpService.post(url, {
			data: {
				assessmentId,
			},
		});
	},

	async validateCandidate(assessmentId: string) {
		const url = `${Config.BACKEND_BASE_URL}/assessment-user-test/validate-interview-candidate`;
		return await HttpService.post(url, {
			data: {
				assessmentId,
			},
		});
	},

	async endInterview(
		assessmentUserTestId: string,
		callId: string
	) {
		const url = `${Config.BACKEND_BASE_URL}/assessment-user-test/end-interview-ui`;
		return await HttpService.post(url, {
			data: {
				assessmentUserTestId,
				callId,
			},
		});
	},
};
